import { UserDataType } from "@/types/auth";
import { EventOrWeddingPathChosen, UserRoleEnum } from "contract/enum";
import isNil from "lodash/isNil";
import { useRouter } from "next/router";
import { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { TimelinePageViewEnum } from "@/types/timeline";
import {
  ActionEnum,
  ExtendedUserRoleEnum,
  PageEnum,
  canPerformAction,
} from "@/data/pageRestriction";
import { ToastStatus, useCustomToast } from "@/hooks/useToast";
import { getRoleFromUserDetails } from "@/root/utils";

export const useAccessRestriction = ({
  userDetails,
  isLoggedIn,
}: {
  userDetails: UserDataType;
  isLoggedIn: boolean;
}) => {
  const router = useRouter();
  const { showToast } = useCustomToast();
  useEffect(() => {
    if (!isLoggedIn || !router.isReady) {
      return;
    }
    const userRole = getRoleFromUserDetails(userDetails);
    //NOTE : If the user is vendor these restrictions are not applicable
    if (
      userRole === ExtendedUserRoleEnum.vendor ||
      userRole === ExtendedUserRoleEnum.proPlannerVendor
    ) {
      return;
    }
    if (
      isNil(userDetails.eventOrWeddingPathChosen) &&
      userRole === UserRoleEnum.spouse
    ) {
      router.push("/choose-events-or-wedding");
      return;
    }
    //NOTE : If the chosen path is events. then wedding flow will be hidden and user will be only able to access Events flow
    if (
      userDetails.eventOrWeddingPathChosen ===
      EventOrWeddingPathChosen.eventPath
    ) {
      return;
    }

 //NOTE : If the chosen path is events. then wedding flow will be hidden and user will be only able to access Festivals flow
    // if (
    //   userDetails.eventOrWeddingPathChosen ===
    //   FestivalsOrWeddingPathChosen.festivalsPath
    // ) {
    //   return;
    // }
    //NOTE : If the user is logged in and does not have a wedding project, then the user needs to choose a path
    if (isEmpty(userDetails.currentProject.id)) {
      router.push("/create-wedding/?pathChosen=false");
      return;
    }
    //NOTE : After the wedding date is set, the owner has to click on continue before going to the any other page
    if (
      (userRole === UserRoleEnum.spouse ||
        userRole === UserRoleEnum.proPlanner) &&
      !isNil(userDetails.currentProject.weddingDate) &&
      !userDetails.hasOwnerSeenkeyMomentsSection
    ) {
      router.push("/key-moments");
      return;
    }

    //NOTE : After logged in, if the  wedding date is set, the homepage will be calendar view page
    if (
      router.pathname === "/" &&
      !isNil(userDetails.currentProject.weddingDate)
    ) {
      router.push(
        `/timeline/?timelinePageViewEnum="${TimelinePageViewEnum.gridView}"`
      );
      return;
    }

    //NOTE : After logged in, if the  wedding date is not set, the homepage will be timeline view page
    if (
      router.pathname === "/" &&
      isNil(userDetails.currentProject.weddingDate)
    ) {
      router.push("/timeline");
      return;
    }

    if (
      router.pathname === "/add-your-wedding-date" &&
      (!canPerformAction({
        role: getRoleFromUserDetails(userDetails),
        pageOrSection: PageEnum.addWeddingDate,
        action: ActionEnum.view,
      }) ||
        !isNil(userDetails.currentProject?.weddingDate))
    ) {
      showToast({
        status: ToastStatus.error,
        id: "errorToastId",
        message: "You are not authorized to view this page.",
      });
      router.push("/profile");
      return;
    }
    if (
      router.pathname === "/edit-your-wedding-date" &&
      !canPerformAction({
        role: getRoleFromUserDetails(userDetails),
        pageOrSection: PageEnum.addWeddingDate,
        action: ActionEnum.view,
      })
    ) {
      showToast({
        status: ToastStatus.error,
        id: "errorToastId",
        message: "You are not authorized to view this page.",
      });
      router.push("/profile");
      return;
    }
  }, [userDetails, isLoggedIn, router.asPath, router.isReady]);
};
